<style scoped>
	.tpm_box{width: 100%;height: 100%;display: flex;flex-direction: column;}
	.tem_body{flex: 1;width: 100%;}
	.tem_header{padding: 5px;display: flex;align-items: center;};
	.tem_body_contair{height: 100%;width: 100%;}
	.tem_footer{text-align: right;padding: 5px;}
	.mb{padding: 0 20px 0 10px;}
	/* 弹窗 */
	.el-dialog__wrapper{position: absolute;left: 0;top: 0;display: flex;align-items: center;overflow: hidden;}
	.el-dialog.my_dialog{margin-top: 0;}
	.tableClass{
		.el-table__fixed-right{
			height: 100% !important; 
		}
		.el-table__fixed {
			height: 110px !important;
		}
	}
</style>
<style>
	table{box-sizing: border-box;}
	/* 用来设置当前页面element全局table 选中某行时的背景色*/
	#p_20202221344 .el-table__body tr.current-row>td{background-color: #FFF!important;color: #333;}
	.mb .el-breadcrumb__inner{font-weight: bold!important;font-size: 1.1rem;}
	.table--btn{margin: 0 10px;cursor: pointer;white-space: nowrap;display: inline-block;}
	.table--btn.delete{color: red;}
	.table--btn.edit{color: blue;}
</style>
<template>
	<div class="tpm_box" id="p_20202221344">
		<div class="tem_header">
			<div class="mb">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item>{{upPageParams.community.name}}</el-breadcrumb-item>
					<el-breadcrumb-item v-if="upPageParams.build">{{upPageParams.build.name}}</el-breadcrumb-item>
					<el-breadcrumb-item v-if="upPageParams.unit">{{upPageParams.unit.name}}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<el-button type="danger" size="small" @click="downLoad">批量上传仪表模板下载<i class="el-icon-download el-icon--right"></i></el-button>
			<el-button type="danger" size="small" @click="uploadModel">模板导入<i class="el-icon-upload el-icon--right"></i></el-button>
			<el-button type="danger" size="small" @click="thisReload">刷新<i class="el-icon-refresh-right"></i></el-button>
		</div>
		<div class="tem_body" id="tem_body">
			<div class="tem_body_contair" id="table_box" v-loading="tableLoading">
				<el-table style="width: 100%" ref="configTable" :data="tableData" class="m--table tableClass" :height="tableHeight"
				 border @row-click="rowClick" highlight-current-row>
					<el-table-column prop="meter_num" label="编号" width="120"></el-table-column>
					<el-table-column prop="meter_type_text" label="仪表类型" width="80"></el-table-column>
					<el-table-column prop="room.code" label="房号" width="100"></el-table-column>
					<el-table-column prop="unit.name" label="单元" width="80"></el-table-column>
					<el-table-column prop="build.name" label="楼宇" width="80"></el-table-column>
					<el-table-column prop="residence.name" label="小区" width="150"></el-table-column>
					<el-table-column prop="now_read" label="当前数值" width="">
						<template slot-scope="scope">
							<span>{{scope.row.now_read}}</span>
							<span style="font-size: 12px;padding-left: 10px;">{{scope.row.unit_text}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="update_time" label="状态" width="">
						<template slot-scope="scope">
							<div>
								<span v-if="scope.row.status == 0">正常</span>
								<span v-else style="color: red;">禁用</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="update_time" label="" width="160" fixed="right">
						<template slot-scope="scope">
							<div>
								<div class="table--btn delete" @click="deleteBuild(scope.row)">删除</div>
								<div class="table--btn edit" @click="editBuild(scope.row)">编辑</div>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<div class="tem_footer">
			<el-pagination @current-change="handleCurrentChange" :current-page="pageNumber" :page-sizes="[20]" :page-size="pageSize"
			 layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
			</el-pagination>
		</div>
		<!-- 编辑弹窗 -->
		<!-- 上传文件 -->
		<el-dialog custom-class="my_dialog" width="30%" top="none" title="批量导入仪表" :visible.sync="fileUploadDialog"
		 destroy-on-close>
			<file_upload v-if="fileUploadDialog" :pageParams="pageParams" v-on:childrenEvent="uploadEvent"></file_upload>
		</el-dialog>
		<!-- 编辑 -->
		<el-dialog custom-class="my_dialog" width="45%" top="none" title="添加仪表" :visible.sync="editDialog"
		 destroy-on-close>
			<edit v-if="editDialog" :pageParams="upPageParams" :obj="editObject" v-on:childrenEvent="editEvent"></edit>
		</el-dialog>

	</div>
</template>

<script>
	import file_upload from './file_upload.vue';
	import edit from './edit.vue';
	var _this;
	export default {
		components: {
			file_upload,edit
		},
		props: ['pageParams'],
		data() {
			return {
				upPageParams: null,
				tableLoading: true,
				tableHeight: 0,
				tableData: [],
				pageNumber: 1,
				pageSize: 20,
				pageTotal: 0,
				selectTableRow: null, // 选中的表格某行
				editDialog: false,
				editObject:null,
				fileUploadDialog: false
			}
		},
		created: function() {
			_this = this;
			_this.upPageParams = _this.pageParams;
		},
		mounted: function() {
			_this.$nextTick(function() {
				_this.tableHeight = document.getElementById("tem_body").offsetHeight;
				_this.api_getTableData();
			})
		},
		methods: {
			/* * 刷新 */
			thisReload() {
				_this.selectTableRow = null;
				_this.tableHeight = document.getElementById("tem_body").offsetHeight;
				_this.api_getTableData();
			},
			/* * 切换分页 */
			handleCurrentChange(val) {
				_this.pageNumber = val;
				_this.api_getTableData();
			},
			/* * 获取表格数据 */
			api_getTableData() {
				_this.tableLoading = true;
				var build_id, unit_id, room_id;
				if (_this.upPageParams.build) {
					build_id = _this.upPageParams.build.id;
				}
				if (_this.upPageParams.unit) {
					unit_id = _this.upPageParams.unit.id;
				}

				_this._postApi('/wy/meter/getList', {
					residence_id: _this.upPageParams.community.id,
					build_id: build_id,
					unit_id: unit_id,
					page: _this.pageNumber,
					limit: _this.pageSize
				}).then((res) => {
					if (res.code == 1) {
						var baseData = res.data.list;
						_this.pageTotal = res.data.count;
						_this.tableData = baseData;
						_this.tableLoading = false;
					} else {

					}
				}).catch((err) => {});
			},

			/* * 选择某行 */
			rowClick(row, column, event) {
				_this.selectTableRow = row;
			},

			// _ ** 编辑
			editBuild(obj) {
				_this.editObject = obj;
				_this.editDialog = true;
			},

			// _ ** 删除
			deleteBuild(obj) {
				var h = _this.$createElement;
				_this.$msgbox({
					title: '不可逆操作',
					message: h('p', null, [
						h('span', null, '确定要删除 '),
						h('span', {
							style: 'color: teal'
						}, obj.meter_name+'  编号为  '+ obj.meter_num + ' 吗？')
					]),
					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					beforeClose: (action, instance, done) => {
						if (action === 'confirm') {
							instance.confirmButtonLoading = true;
							instance.confirmButtonText = '执行中...';
							_this._postApi('/wy/meter/delete', {
								mid: obj.mid
							}).then((res) => {
								if (res.code == 1) {
									_this.thisReload();
									_this.$message({
										message: '删除成功！',
										type: 'success',
										duration: 1000,
										onClose: function() {
											done();
											instance.confirmButtonLoading = false;
										}
									});
								} else {
									_this.$message({
										message: res.msg,
										type: 'error',
										onClose: function() {
											done();
											instance.confirmButtonLoading = false;
										}
									});
								}
							}).catch((err) => {});
						} else {
							done();
						}
					}
				}).then(action => {

				}).catch(() => {});
			},
			// _ *** 下载模板
			downLoad() {
				_this._axios({
					method: 'get',
					url: '/wy/meter/template',
					headers: {
						"token": localStorage.getItem('token')
					},
					responseType: 'arraybuffer'
				}).then((response) => {
					// console.log(response.getResponseHeaders('content-type'));
					//console.log(response)
					let blob = new Blob([response.data], {
						// type: 'application/vnd.ms-excel;charset-UTF-8'
						type: 'application/zip'
					})
					let downloadElement = document.createElement('a');
					let href = window.URL.createObjectURL(blob); //创建下载的链接
					downloadElement.href = href;
					downloadElement.download = '批量仪表上传模板.zip'; //下载后文件名
					document.body.appendChild(downloadElement);
					downloadElement.click(); //点击下载
					document.body.removeChild(downloadElement); //下载完成移除元素
					window.URL.revokeObjectURL(href); //释放掉blob对象
				}).catch(() => {

				});
			},
			// _ *** 上传模板
			uploadModel() {
				_this.fileUploadDialog = true;
			},

			// _ *** 上传弹框事件消息
			uploadEvent(e) {
				switch (e.type) {
					case 'close':
						_this.fileUploadDialog = false;
						break;
					case 'success':
						_this.thisReload();
						_this.fileUploadDialog = false;
						break;
				}
			},
			/** ->
			AUTHOR:郑凯 
			CREATE_TIME:2021-2-22 16:02:36 */
			editEvent(e){
				switch (e.type) {
					case 'close':
						_this.editDialog = false;
						break;
					case 'success':
						_this.thisReload();
						_this.editDialog = false;
						break;
				}
			},
		}
	}
</script>
