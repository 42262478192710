// 收费公式计费参数
export var chargeParam = [{
	value: '1',
	label: '建筑面积'
}, {
	value: '2',
	label: '使用面积'
}, {
	value: '0',
	label: '无'
}];

// 返回计费参数
export var getChargeParamByValue = function(value) {
	var label = '';
	chargeParam.forEach(function(item) {
		if (item.value == value) {
			label = item.label;
		}
	});
	return label;
};

export var sq_zq = [{
	value: '1',
	label: '按月'
}, {
	value: '2',
	label: '按季度'
}, {
	value: '3',
	label: '按年'
}];

/** --------> 介绍: 未缴费处理,费用生成 
author:郑凯 创建时间:2021-2-4 10:55:15 */
export var no_pay_op = [{
	value: '1',
	label: '不变'
}, {
	value: '2',
	label: '重算'
}, {
	value: '3',
	label: '清除'
}];

/** --------> 介绍: 	启费日期是否参与计算,费用生成 
author:郑凯 创建时间:2021-2-4 10:55:15 */
export var start_op = [{
	value: '1',
	label: '参与计算'
}, {
	value: '2',
	label: '不参与计算'
}];

/** ->  遇到相同类型仪表是否添加
AUTHOR:郑凯 
CREATE_TIME:2021-2-22 14:53:45 */
export var is_add = [{
	value: '0',
	label: '不添加'
}, {
	value: '1',
	label: '添加'
}];

/** ->  仪表状态
AUTHOR:郑凯 
CREATE_TIME:2021-2-22 14:54:51 */
export var meter_status = [{
	value: '0',
	label: '正常'
}, {
	value: '1',
	label: '禁用'
}];