<style scoped>
	div,input,textarea{box-sizing: border-box;}
	.page_box_add_community{display: flex;flex-direction: column;overflow: hidden;}
	.page_box_body{overflow: auto;flex: 1;}
	.page_box_footer{padding: 10px 10px 0 10px;text-align: right;}
	/* 表单 */
	
	.z_form_item_row{padding: 10px 0;}
	.z_form_item{flex: 1;display: flex;flex-direction: row;align-items:center ;}
	.z_form_item_label{width: 100px;text-align: right;padding-right: 10px;}
	.z_form_item_value{flex: 1;}
	.z_form input{height: 30px;width: 100%;}
	.z_form textarea{height: 80px;width: 100%;padding: 5px;}
</style>
<style>
	.z_form .el-form-item{margin-bottom: 0;}
	.z_form .el-form-item__content{margin-left: 0!important;}
</style>
<template>
	<div class="page_box_add_community">
		<div class="page_box_header"></div>
		<div class="page_box_body">
			<div class="z_form">
				<el-form label-width="80px" :model="form" :rules="rules" ref="form">
					<el-row :gutter="0" class="z_form_item_row">
						<el-col :span="12">
							<div class="z_form_item">
								<div class="z_form_item_label">仪表类型</div>
								<div class="z_form_item_value">
									<el-select v-model="form.meter_type" placeholder="请选择" @change="meterTypeChange">
										<el-option v-for="item in meterTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
									</el-select>
								</div>
							</div>
						</el-col>
						<el-col :span="12">
							<div class="z_form_item">
								<div class="z_form_item_label">状态</div>
								<div class="z_form_item_value">
									<el-select v-model="form.status" placeholder="请选择">
										<el-option v-for="item in meterStatusOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
									</el-select>
								</div>
							</div>
						</el-col>
					</el-row>
					<el-row :gutter="0" class="z_form_item_row">
						<el-col :span="12">
							<div class="z_form_item">
								<div class="z_form_item_label">名称</div>
								<div class="z_form_item_value">
									<el-input v-model="form.meter_name"></el-input>
								</div>
							</div>
						</el-col>
						<el-col :span="12">
							<div class="z_form_item">
								<div class="z_form_item_label">编号</div>
								<div class="z_form_item_value">
									<el-input v-model="form.meter_num"></el-input>
								</div>
							</div>
						</el-col>
					</el-row>
					<el-row :gutter="0" class="z_form_item_row">
						<el-col :span="12">
							<div class="z_form_item">
								<div class="z_form_item_label">户号</div>
								<div class="z_form_item_value">
									<el-input v-model="form.account_num"></el-input>
								</div>
							</div>
						</el-col>
						<el-col :span="12">
							<div class="z_form_item">
								<div class="z_form_item_label">倍率</div>
								<div class="z_form_item_value">
									<el-input v-model="form.magnification"></el-input>
								</div>
							</div>
						</el-col>
					</el-row>
					<el-row :gutter="0" class="z_form_item_row">
						<el-col :span="12">
							<div class="z_form_item">
								<div class="z_form_item_label">量程</div>
								<div class="z_form_item_value">
									<el-input v-model="form.range"></el-input>
								</div>
							</div>
						</el-col>
						<el-col :span="12">
							<div class="z_form_item">
								<div class="z_form_item_label">起始读数</div>
								<div class="z_form_item_value">
									<el-input v-model="form.qs_ds"></el-input>
								</div>
							</div>
						</el-col>
					</el-row>
					<el-row :gutter="0" class="z_form_item_row">
						<el-col :span="12">
							<div class="z_form_item">
								<div class="z_form_item_label">计量单位</div>
								<div class="z_form_item_value">
									<el-select v-model="form.jl_dw" placeholder="请选择">
										<el-option v-for="item in jlDwList" :key="item.id" :label="item.name" :value="item.id"></el-option>
									</el-select>
								</div>
							</div>
						</el-col>
						<el-col :span="12">
							<div class="z_form_item">
								<div class="z_form_item_label">遇到相同类型仪表是否添加</div>
								<div class="z_form_item_value">
									<el-select v-model="form.is_add" placeholder="请选择">
										<el-option v-for="item in isAddOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
									</el-select>
								</div>
							</div>
						</el-col>
					</el-row>
					<el-row :gutter="0" class="z_form_item_row">
						
						
					</el-row>
				</el-form>
			</div>
		</div>
		<div class="page_box_footer">
			<el-button @click="closeThisPage()">取消</el-button>
			<el-button type="success" :loading="btnSaveLoading" @click="onSubmit">立即保存</el-button>
		</div>
	</div>
</template>

<script>
	import {
		is_add,meter_status
	} from '../../../assets/js/config.js' //注意路径
	var _this;
	export default {
		props:['pageParams'],
		data() {
			return {
				form: {
					meter_type: '',
					meter_name: '',
					meter_num: '',
					account_num: '',
					magnification: '1',
					range: '999999',
					qs_ds: '',
					jl_dw: '',
					is_add: '1',
					status: '',
					residence_id: '',
					build_id: '',
					unit_id: '',
					room_id: ''
				},
				rules: null,
				btnSaveLoading: false,
				meterTypeList:[],
				isAddOptions:[],
				meterStatusOptions:[],
				jlDwList:[]
			}
		},
		created: function() {
			_this = this;
			_this.isAddOptions = is_add;
			_this.meterStatusOptions = meter_status;
			_this.apiGetMeterTypeList();
		},
		methods: {
			/** ->  获取仪表类型
			AUTHOR:郑凯 
			CREATE_TIME:2021-2-22 14:31:37 */
			apiGetMeterTypeList(){
				_this._getApi('/wy/meter/types',{
					
				}).then((res) => {
					if (res.code == 1) {
						var baseData = res.data;
						baseData.forEach(function(item){
							item.id = item.id+'';
						})
						_this.meterTypeList = baseData;
					} else {

					}
				}).catch((err) => {});
				_this._getApi('/wy/meter/units',{
					
				}).then((res) => {
					if (res.code == 1) {
						var baseData = res.data;
						baseData.forEach(function(item){
							item.id = item.id+'';
						})
						_this.jlDwList = baseData;
					} else {
				
					}
				}).catch((err) => {});
			},

			/** ->  
			AUTHOR:郑凯 
			CREATE_TIME:2021-2-22 16:41:33 */
			meterTypeChange(e){
				console.log(e)
				var baseData = _this.meterTypeList;
				baseData.forEach(function(item){
					if(item.id == e){
						_this.form.meter_name = item.name
					}
				})
			},
			
			/** ->  提交保存
			AUTHOR:郑凯 
			CREATE_TIME:2021-2-22 15:02:32 */
			onSubmit() {
				_this.btnSaveLoading = true;
				var params = _this.form;
				params.room_id = _this.pageParams.room.id;
				params.residence_id = _this.pageParams.community.id;
				if (params.meter_type == '') {
					_this.customTs('类型必选');
					return;
				}
				if (params.is_add == '') {
					_this.customTs('遇到相同类型仪表是否添加必选');
					return;
				}
				if (params.status == '') {
					_this.customTs('状态必选');
					return;
				}
				if (params.meter_num == '') {
					_this.customTs('编号必填');
					return;
				}
				if (params.meter_name == '') {
					_this.customTs('名称必填');
					return;
				}
				_this._postApi('/wy/meter/create', params).then((res) => {
					if (res.code == 1) {
						_this.btnSaveLoading = false;
						_this.$emit('childrenEvent', {
							type:'success'
						});
					} else {
						_this.btnSaveLoading = false;
						_this.customTs(res.msg);
					}
				}).catch((err) => {

				})
			},
			
			/** ->  消息提示
			AUTHOR:郑凯 
			CREATE_TIME:2021-2-22 15:07:23 */
			customTs(msg){
				_this.$confirm(msg, '提示', {
					confirmButtonText: '确定',
					showCancelButton:false,
					type: 'warning'
				}).then(() => {
				
				}).catch(() => {
				
				});
				_this.btnSaveLoading = false;
			},
			
			/** ->  关闭
			AUTHOR:郑凯 
			CREATE_TIME:2021-2-22 15:45:07 */
			closeThisPage(){
				_this.$emit('childrenEvent', {
					type:'close'
				});
			}
		}
	}
</script>
